<template>
  <div>
    <OnlyCropItem></OnlyCropItem>
  </div>
</template>

<script>
import OnlyCropItem from "~/components/sections/onlyCropItem.vue";

export default {
  name: "RemoverCrop",
  metaInfo: {
    title: "Remover & Crop ",
  },
  data() {
    return {};
  },
  components: {
    OnlyCropItem,
  },
};
</script>

<style lang="scss" scoped></style>
